import React, { useEffect } from "react";
import { PageProps } from "gatsby";
import styled from "styled-components";

import SEO from "../components/SEO";
import { H1 } from "../components/Text";
import { PaddingContainer } from "../components/Containers";
import useCartStore from "../stores/useCartStore";

const InfoContainer = styled.div`
  max-width: 64ch;
  display: grid;
  gap: 2rem 0;
`;

const DutchPaymentSucceededPage: React.FC<PageProps> = ({ location }) => {
  const { emptyCart } = useCartStore();
  useEffect(() => emptyCart(), []);

  return (
    <>
      <SEO title="Betaling geslaagd" lang="nl" pathName={location.pathname} />
      <PaddingContainer>
        <H1>Betaling geslaagd</H1>
        <InfoContainer>
          <p>De betaling is geslaagd en uw bestelling wordt verwerkt.</p>
        </InfoContainer>
      </PaddingContainer>
    </>
  );
};

export default DutchPaymentSucceededPage;
